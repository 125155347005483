:root {
  --white: #ffffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.logo {
  width: 60%;
  height: auto;
  margin: 10px;
}

.emailVerifyingContainer {
  display: flex;
  align-items: center;
  min-height: 60vh;
}

.subMenuItem {
  border-radius: 9px;
}

.ant-layout-sider-zero-width-trigger {
  top: -56px !important;
  left: 0px;
  background: #a544a5 !important;
  color: #ffffff !important;
}

header.ant-layout-header.site-layout-background {
  position: sticky;
  top: 0;
  background: var(--white) !important;
  z-index: 5;
}

/* margin for body and footer */
.layout-content-margin {
  margin-left: 200px;
  padding: 8px;
}

@media screen and (max-width: 480px) {
  .layout-content-margin {
    margin-left: 0px;
  }
}

.menu-padding {
  padding: 9px !important;
  display: flex;
  flex-direction: column;
  min-height: 312px;
  height: calc(100vh - 70px) !important;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px !important;
}

ul.ant-menu.ant-menu-dark.menu-padding.ant-menu-root.ant-menu-inline > :last-child {
  margin-top: auto;
  align-items: center;
}

aside.ant-layout-sider.ant-layout-sider-dark {
  height: calc(100vh - 65px) !important;
  z-index: 5;
}

.nav-logo-container {
  float: left;
  width: 120px;
  height: 50px;
  margin: 7px 24px 7px 0;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .nav-logo-container {
    margin: 7px auto;
    float: none;
  }
}

.nav-logo {
  width: 100%;
  height: auto;
}

.user-avatar-name {
  justify-content: center;
  min-height: 30px;
  margin: 7px auto;
  display: flex;
  align-items: center;
  text-align: center;
}

.ant-layout-sider-children {
  overflow: auto;
}

.dashboard-col-style {
  padding: 5px;
  text-align: left;
}

.main-content-div {
  padding: 20px;
  text-align: left;
}

.ant-input-group > .ant-row {
  flex-direction: column;
}

.event-embed-container {
  width: 100%;
  height: auto;
}

.even-embed-iframe-div {
  padding: 56.25% 0 0 0;
  position: relative;
}

.even-embed-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.chat-embed-container {
  width: 100%;
  height: 400px;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
header.ant-layout-header.site-layout-background {
  background: #a544a5 !important;
}



.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  color: #a544a5 !important;
  background: #ffffff !important;

}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: #ffffff !important;
  background-color: #a544a5 !important;
  font-weight:bold !important;

}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a {
  color: #a544a5 !important;

}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item {
  color: #ffffff !important;

}
.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a:hover{
  color: #000000 !important;

}

a, .ant-avatar {
  color: #a544a5 !important;

}

.steps-content {
  min-height: 200px;
  margin-top: 16px;
  /* padding-top: 80px; */
  /* text-align: center; */
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
  text-align: right;
}


.ant-divider-horizontal.ant-divider-with-text{
  color:#a544a5 !important;
  /* white-space: pre-wrap !important; */
  /* overflow-wrap: anywhere !important; */
}

.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  /* height: 120px; */
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #141414;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}

.btnStyle{
  color: #ffffff !important;
  background: #eb2f96  !important;
  font-weight:bold !important;
  border-color: #eb2f96 !important;
}

button{
  font-weight:bold !important;

}

.switchBtnStyle{
  color: #ffffff !important;
  background: #39aa00  !important;
  font-weight:bold !important;
  border-color: #39aa00 !important;
  margin: 15px 0px;

}

.syllabusBtnStyle{
  color: #ffffff !important;
  background: #1d9296  !important;
  font-weight:bold !important;
  border-color: #1d9296 !important;
  margin: 15px 0px;
}

.topicBtnStyle{
  color: #ffffff !important;
  background: #bb9f01  !important;
  font-weight:bold !important;
  border-color: #bb9f01 !important;
}


.deleteBtnStyle{
  color: #ffffff !important;
  background: #b80000  !important;
  font-weight:bold !important;
  border-color: #b80000 !important;
}

.viewBtnStyle
{
  color: #ffffff !important;
  background: #6e00b8  !important;
  font-weight:bold !important;
  border-color: #6e00b8 !important;
}

.ant-card-head-title, .ant-list-item-meta-title, .ant-form-item-control-input-content, .ant-card-body
  {
  overflow-wrap: anywhere !important;
  white-space: normal !important;
}


.ant-statistic{
    text-align: center;
}

/* div > div.ant-list.ant-list-vertical.ant-list-split.ant-list-something-after-last-item > div.ant-list-pagination > ul.ant-pagination > li.ant-pagination-prev,
div > div.ant-list.ant-list-vertical.ant-list-split.ant-list-something-after-last-item > div.ant-list-pagination > ul.ant-pagination >li.ant-pagination-next,
div > div.ant-list.ant-list-vertical.ant-list-split.ant-list-something-after-last-item > div.ant-list-pagination > ul.ant-pagination >li.ant-pagination-jump-prev,
div > div.ant-list.ant-list-vertical.ant-list-split.ant-list-something-after-last-item > div.ant-list-pagination > ul.ant-pagination > li.ant-pagination-jump-next {
  display: none !important;
} */


